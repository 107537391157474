(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/racing/assets/javascripts/get-racing-classname.js') >= 0) return;  svs.modules.push('/components/utils/racing/assets/javascripts/get-racing-classname.js');
"use strict";


let resolveAnyRacingInput;
if (svs.isServer) {
  const path = require('path');
  resolveAnyRacingInput = require(path.join(__dirname, 'resolve-any-racing-input.es6'));
} else {
  resolveAnyRacingInput = svs.utils.racing.resolveAnyRacingInput;
}
function getRacingClassName(anyInput) {
  if (resolveAnyRacingInput(anyInput)) {
    return 'hastar';
  }
  throw new Error("Product id ".concat(anyInput, " is not a racing id"));
}
if (svs.isServer) {
  module.exports = getRacingClassName;
} else {
  svs.utils.racing.getRacingClassName = getRacingClassName;
}

 })(window);